import React from 'react';
import './Drones.css';

const translations = {
  pt: {
    title: "Drones",
    description: "Integramos drones equipados com câmeras de alta resolução para monitoramento aéreo, possibilitando a cobertura de grandes áreas com eficiência e precisão."
  },
  es: {
    title: "Drones",
    description: "Integramos drones equipados con cámaras de alta resolución para monitoreo aéreo, posibilitando la cobertura de grandes áreas con eficiencia y precisión."
  },
  en: {
    title: "Drones",
    description: "We integrate drones equipped with high-resolution cameras for aerial monitoring, enabling the coverage of large areas with efficiency and precision."
  }
};

const Drones = ({ language }) => {
  const currentTranslations = translations[language] || translations['pt'];

  return (
    <div className="drones" id="drones-section">
      <div className="overlay"></div>
      <div className="drones-content">
        <h2>{currentTranslations.title}</h2>
        <div className="drones-text">
          <p>
            {currentTranslations.description}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Drones;
