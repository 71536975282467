import React from 'react';
import './WhoWeAre.css';

const translations = {
  pt: {
    title: "Quem Somos",
    description: "Somos uma equipe apaixonada por Segurança e Tecnologia. Nosso objetivo é proporcionar Tranquilidade e Proteção através da inovação. Combinamos expertise em drones e robótica, adjunto a Inteligência Artificial para criar soluções de segurança de ponta."
  },
  es: {
    title: "Quiénes Somos",
    description: "Somos un equipo apasionado por la Seguridad y la Tecnología. Nuestro objetivo es proporcionar Tranquilidad y Protección a través de la innovación. Combinamos experiencia en drones y robótica, junto con Inteligencia Artificial para crear soluciones de seguridad de vanguardia."
  },
  en: {
    title: "Who We Are",
    description: "We are a team passionate about Security and Technology. Our goal is to provide Peace of Mind and Protection through innovation. We combine expertise in drones and robotics, along with Artificial Intelligence, to create cutting-edge security solutions."
  }
};

const WhoWeAre = ({ language }) => {
  const currentTranslations = translations[language] || translations['pt'];

  return (
    <div className="who-we-are" id="who-we-are-section">
      <div className="overlay"></div>
      <div className="who-we-are-content">
        <h3>{currentTranslations.title}</h3>
        <p>{currentTranslations.description}</p>
      </div>
    </div>
  );
};

export default WhoWeAre;
