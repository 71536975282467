import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Register from './components/Register';
import Login from './components/Login';
import Services from './Services';
import WhoWeAre from './WhoWeAre'; // Importar WhoWeAre com suporte para tradução
import Header from './Header';
import Innovation from './Innovation';
import Robots from './Robots';
import Drones from './Drones';
import Welcome from './Welcome';
import Cameras from './Cameras';
import Ocorrencias from './Ocorrencias';
import logoVJ from './images/Logo_VJ.jpeg';
import './App.css';
import ForgotPassword from './components/ForgotPassword';
import ValidateCode from './components/ValidateCode';
import ChangePassword from './components/ChangePassword';

const App = () => {
    const [language, setLanguage] = useState('pt'); // Estado que controla o idioma selecionado

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <Router>
            <div className="app-container">
                {/* Passando `language` e `setLanguage` para o Header */}
                <Header language={language} setLanguage={setLanguage} />
                <Routes>
                    <Route path="/register" element={<Register language={language} />} />
                    <Route path="/login" element={<Login language={language} />} />
                    <Route path="/welcome" element={<Welcome language={language} />} />
                    <Route path="/cameras" element={<Cameras />} />
                    <Route path="/ocorrencias" element={<Ocorrencias language={language} />} />
                    <Route path="/forgot-password" element={<ForgotPassword language={language} />} />
                    <Route path="/validate-code" element={<ValidateCode language={language} />} />
                    <Route path="/change-password" element={<ChangePassword language={language} />} />
                    <Route path="/" element={
                        <>
                            <div className="hero">
                                <div className="hero-content">
                                    <h1 className="ai-text">
                                        {language === 'pt' ? "Inteligência Artificial (IA)" : language === 'es' ? "Inteligencia Artificial (IA)" : "Artificial Intelligence (AI)"}
                                    </h1>
                                    <h1 className="security-text">
                                        <span></span> {language === 'pt' ? " e Segurança Eletrônica" : language === 'es' ? "y Seguridad Electrónica" : "and Electronic Security"}
                                    </h1>
                                </div>
                                <button onClick={() => scrollToSection('innovation-section')} className="scroll-button">
                                    {language === 'pt' ? "Saiba mais" : language === 'es' ? "Aprende más" : "Learn more"}
                                </button>
                            </div>
                            {/* Passando o estado `language` para os componentes */}
                            <Innovation language={language} />
                            <Robots language={language} />
                            <Drones language={language} />
                            <div id="services-section">
                                {/* Passando o estado `language` para o componente Services */}
                                <Services language={language} />
                            </div>
                            {/* WhoWeAre com suporte para tradução */}
                            <div id="who-we-are-section">
                                <WhoWeAre language={language} />
                            </div>
                            <div className="contact-section">
                                <h2>{language === 'pt' ? "Contato" : language === 'es' ? "Contacto" : "Contact"}</h2>
                                <p>
                                    {language === 'pt'
                                        ? "Estamos sempre disponíveis para atendê-lo e fornecer mais informações sobre nossos serviços. Entre em contato conosco:"
                                        : language === 'es'
                                        ? "Siempre estamos disponibles para atenderle y proporcionarle más información sobre nuestros servicios. Contáctenos:"
                                        : "We are always available to assist you and provide more information about our services. Get in touch with us:"}
                                </p>
                                <a href="https://api.whatsapp.com/send?phone=5511918791466&text=Ol%C3%A1,%20vim%20pelo%20site%20Droone%20e%20gostaria%20de%20saber%20mais?" className="contact-button">
                                    {language === 'pt' ? "Saiba Mais" : language === 'es' ? "Aprende más" : "Learn More"}
                                </a>
                            </div>
                            <footer>
                                <div className="footer-content">
                                    <div className="social-icons">
                                        <a href="https://www.instagram.com/droo.ne/"><i className="fab fa-instagram"></i></a>
                                        <a href="https://api.whatsapp.com/send?phone=5511918791466&text=Ol%C3%A1,%20vim%20pelo%20site%20Droone%20e%20gostaria%20de%20saber%20mais?"><i className="fab fa-whatsapp"></i></a>
                                        <a href="https://www.youtube.com/@Dro-one"><i className="fab fa-youtube"></i></a>
                                        <a href="https://vjbots.com.br/">
                                            <img src={logoVJ} alt="Logo VJ" className="footer-logo" />
                                        </a>
                                    </div>
                                    <p>
                                        {language === 'pt' ? "© 2024 Droone. Todos os direitos reservados." : language === 'es' ? "© 2024 Droone. Todos los derechos reservados." : "© 2024 Droone. All rights reserved."}
                                    </p>
                                </div>
                            </footer>
                        </>
                    } />
                </Routes>
            </div>
        </Router>
    );
};

export default App;
