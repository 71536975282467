import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom'; // Importar o useNavigate e useLocation
import './Login.css'; // Reutilizando o CSS do Login

const ValidateCode = ({ language }) => { // Recebendo a linguagem como prop
    const [code, setCode] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate(); // Inicializando o hook
    const location = useLocation(); // Para acessar o email passado pela rota
    const { email } = location.state || {}; // Extrair o email da rota

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSuccessMessage('');
        setErrorMessage('');

        if (!email) {
            setErrorMessage(language === 'pt' ? 'Email não fornecido.' : language === 'es' ? 'Correo electrónico no proporcionado.' : 'Email not provided.');
            return;
        }

        try {
            const response = await axios.post('https://ba2add21-2f50-4d9e-84c9-1c06ee3cb5e3-00-1bm00jl761pt7.worf.replit.dev:8000/auth/validate_token', {
                email,
                token: code
            });

            if (response.status === 200) {
                setSuccessMessage(language === 'pt' ? 'Código validado com sucesso!' : language === 'es' ? '¡Código validado con éxito!' : 'Code successfully validated!');
                setTimeout(() => {
                    // Redirecionar para a página de troca de senha e passar o email e token como parâmetros
                    navigate('/change-password', { state: { email, token: code } });
                }, 2000);
            } else {
                setErrorMessage(language === 'pt' ? 'Código inválido. Tente novamente.' : language === 'es' ? 'Código inválido. Inténtalo de nuevo.' : 'Invalid code. Please try again.');
            }
        } catch (error) {
            setErrorMessage(language === 'pt' ? 'Erro ao validar o código. Verifique o código informado.' : language === 'es' ? 'Error al validar el código. Verifique el código ingresado.' : 'Error validating the code. Please check the entered code.');
        }
    };

    return (
        <div className="login-page">
            <div className="login-container">
                <h2>{language === 'pt' ? 'Validar Código' : language === 'es' ? 'Validar Código' : 'Validate Code'}</h2>
                <p>{language === 'pt' ? 'Código enviado para o e-mail:' : language === 'es' ? 'Código enviado al correo electrónico:' : 'Code sent to email:'} {email}</p>
                {successMessage && <p className="success-message">{successMessage}</p>}
                {errorMessage && <p className="error-message">{errorMessage}</p>}
                <form onSubmit={handleSubmit}>
                    <div className="input-group">
                        <label>{language === 'pt' ? 'Insira o código de validação' : language === 'es' ? 'Ingrese el código de validación' : 'Enter the validation code'}</label>
                        <input
                            type="text"
                            value={code}
                            onChange={(e) => setCode(e.target.value)}
                            required
                        />
                    </div>
                    <button type="submit" className="login-button">
                        {language === 'pt' ? 'Validar Código' : language === 'es' ? 'Validar Código' : 'Validate Code'}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default ValidateCode;
