import React from 'react';
import './Robots.css';

const translations = {
  pt: {
    title: "Robôs Terrestres",
    description: "Integração com robôs terrestres equipados com câmeras e sensores para inspeção detalhada de áreas com uma visão precisa."
  },
  es: {
    title: "Robots Terrestres",
    description: "Integración con robots terrestres equipados con cámaras y sensores para una inspección detallada de áreas con una visión precisa."
  },
  en: {
    title: "Ground Robots",
    description: "Integration with ground robots equipped with cameras and sensors for detailed inspection of areas with precise vision."
  }
};

const Robots = ({ language }) => {
  const currentTranslations = translations[language] || translations['pt'];

  return (
    <div className="robots" id="robots-section">
      <div className="overlay"></div>
      <div className="robots-content">
        <h2>{currentTranslations.title}</h2>
        <div className="robots-text">
          <p>
            {currentTranslations.description}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Robots;
