import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import logo from './images/Logo_Droone.webp';
import brazilFlag from './images/brazil_flag.png';
import spainFlag from './images/spain_flag.png';
import usaFlag from './images/usa_flag.png';
import './Header.css';

const translations = {
  pt: {
    home: "Início",
    welcome: "Bem-vindo",
    occurrences: "Ocorrências",
    technologies: "Tecnologias",
    services: "Nossos Serviços",
    whoWeAre: "Quem Somos",
    customerArea: "Área do Cliente"
  },
  es: {
    home: "Inicio",
    welcome: "Bienvenido",
    occurrences: "Incidencias",
    technologies: "Tecnologías",
    services: "Nuestros Servicios",
    whoWeAre: "Quiénes Somos",
    customerArea: "Área del Cliente"
  },
  en: {
    home: "Home",
    welcome: "Welcome",
    occurrences: "Occurrences",
    technologies: "Technologies",
    services: "Our Services",
    whoWeAre: "Who We Are",
    customerArea: "Customer Area"
  }
};

const Header = ({ language, setLanguage }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 769);
  const [selectedFlag, setSelectedFlag] = useState(brazilFlag);
  const [isLanguagePopupOpen, setIsLanguagePopupOpen] = useState(false);

  useEffect(() => {
    const user = localStorage.getItem('user');
    setIsLoggedIn(!!user);
  }, [location]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 769);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(prevState => !prevState);
  };

  const handleLogout = () => {
    localStorage.removeItem('user');
    setIsLoggedIn(false);
    setIsMobileMenuOpen(false);
    navigate('/');
  };

  const handleNavClick = (event, sectionId) => {
    event.preventDefault();
    if (location.pathname !== '/') {
      navigate('/');
      setIsMobileMenuOpen(false);
      setTimeout(() => {
        const element = document.getElementById(sectionId);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, 500);
    } else {
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
        setIsMobileMenuOpen(false);
      }
    }
  };

  const handleLanguageChange = (flag, lang) => {
    setSelectedFlag(flag);
    setLanguage(lang); // Atualiza o estado da linguagem no App.js
    setIsLanguagePopupOpen(false);
  };

  // Usa as traduções de acordo com o idioma selecionado
  const currentTranslations = translations[language] || translations['pt'];

  return (
    <header>
      <div className="logo-bandeira-container">
        <Link to="/" className="logo">
          <img src={logo} alt="Logo" />
        </Link>
        <div className="language-selector">
          <img 
            src={selectedFlag} 
            alt="Selected Flag" 
            className="language-flag selected" 
            onClick={() => setIsLanguagePopupOpen(!isLanguagePopupOpen)} 
          />
          {isLanguagePopupOpen && (
            <div className="language-popup">
              <img 
                src={brazilFlag} 
                alt="Brazil Flag" 
                className="language-flag" 
                onClick={() => handleLanguageChange(brazilFlag, 'pt')} 
              />
              <img 
                src={spainFlag} 
                alt="Spain Flag" 
                className="language-flag" 
                onClick={() => handleLanguageChange(spainFlag, 'es')} 
              />
              <img 
                src={usaFlag} 
                alt="USA Flag" 
                className="language-flag" 
                onClick={() => handleLanguageChange(usaFlag, 'en')} 
              />
            </div>
          )}
        </div>
      </div>
      <nav className={isLoggedIn ? "logged-in-nav" : "desktop-nav"}>
        <ul>
          {isLoggedIn ? (
            <>
              <li>
                <Link to="/welcome" onClick={() => setIsMobileMenuOpen(false)}>{currentTranslations.welcome}</Link>
              </li>
              <li>
                <Link to="/ocorrencias" onClick={() => setIsMobileMenuOpen(false)}>{currentTranslations.occurrences}</Link>
              </li>
              <li>
                <button onClick={handleLogout} className="logout-button">Sair</button>
              </li>
            </>
          ) : (
            <>
              <li>
                <Link to="/" onClick={() => setIsMobileMenuOpen(false)}>{currentTranslations.home}</Link>
              </li>
              <li>
                <a href="#robots-section" onClick={(e) => handleNavClick(e, 'robots-section')}>{currentTranslations.technologies}</a>
              </li>
              <li>
                <a href="#services-section" onClick={(e) => handleNavClick(e, 'services-section')}>{currentTranslations.services}</a>
              </li>
              <li>
                <a href="#who-we-are-section" onClick={(e) => handleNavClick(e, 'who-we-are-section')}>{currentTranslations.whoWeAre}</a>
              </li>
              <li>
                <Link to="/login" className="login-button" onClick={() => setIsMobileMenuOpen(false)}>{currentTranslations.customerArea}</Link>
              </li>
            </>
          )}
        </ul>
      </nav>
      {isMobile && (
        <div className="hamburger-menu" onClick={toggleMobileMenu}>
          <i className="fas fa-bars"></i>
        </div>
      )}
      {isMobileMenuOpen && (
        <nav className="mobile-nav open">
          <ul>
            {isLoggedIn ? (
              <>
                <li>
                  <Link to="/welcome" onClick={() => setIsMobileMenuOpen(false)}>{currentTranslations.welcome}</Link>
                </li>
                <li>
                  <Link to="/ocorrencias" onClick={() => setIsMobileMenuOpen(false)}>{currentTranslations.occurrences}</Link>
                </li>
                <li>
                  <button onClick={handleLogout} className="logout-button">Sair</button>
                </li>
              </>
            ) : (
              <>
                <li>
                  <Link to="/" onClick={() => setIsMobileMenuOpen(false)}>{currentTranslations.home}</Link>
                </li>
                <li>
                  <a href="#robots-section" onClick={(e) => handleNavClick(e, 'robots-section')}>{currentTranslations.technologies}</a>
                </li>
                <li>
                  <a href="#services-section" onClick={(e) => handleNavClick(e, 'services-section')}>{currentTranslations.services}</a>
                </li>
                <li>
                  <a href="#who-we-are-section" onClick={(e) => handleNavClick(e, 'who-we-are-section')}>{currentTranslations.whoWeAre}</a>
                </li>
                <li>
                  <Link to="/login" className="login-button" onClick={() => setIsMobileMenuOpen(false)}>{currentTranslations.customerArea}</Link>
                </li>
              </>
            )}
          </ul>
        </nav>
      )}
    </header>
  );
};

export default Header;
