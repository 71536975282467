import React from 'react';
import styled from 'styled-components';
import { space, layout, typography, flexbox } from 'styled-system';
import droneImage from './images/drone-1.webp'; // Certifique-se de que o caminho está correto

const ServicesWrapper = styled.div`
  ${space}
  ${layout}
  background-color: #f9f9f9;
  margin: 0 auto;
  text-align: center;
  max-width: 1200px; /* Mantendo o max-width original */
`;

const ServiceCategory = styled.div`
  margin-bottom: 50px;
`;

const ServiceSubtitle = styled.div`
  ${layout}
  ${space}
  margin: 0 auto;
  text-align: center;
`;

const ServiceTitle = styled.h2`
  ${typography}
  color: #007bff;
  font-weight: bold;
  font-family: 'Montserrat', sans-serif;
`;

const ServiceGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  flex-direction: row;

  @media (max-width: 1024px) {
    flex-direction: column; /* Alinhar verticalmente em resoluções menores */
    align-items: center;
  }
`;

const ServiceBox = styled.div`
  ${space}
  ${layout}
  ${typography}
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  width: 300px; /* Mantendo a largura original */
  text-align: left;

  &:hover {
    transform: translateY(-10px);
  }
`;

const ServiceBoxTitle = styled.h4`
  ${typography}
  color: #333;
  margin-bottom: 10px;
  font-family: 'Montserrat', sans-serif;
`;

const ServiceBoxText = styled.p`
  ${typography}
  color: #555;
  line-height: 1.6;
  font-family: 'Montserrat', sans-serif;
`;

const ServiceImage = styled.img`
  ${layout}
  display: block;
  margin: 0 auto 30px;
  max-width: 100%;
`;

const Services = ({ language }) => {
  return (
    <ServicesWrapper p={[2, 3, 4]}>
      <ServiceCategory>
        <ServiceSubtitle maxWidth={['100%', '90%', '80%']}>
          <ServiceTitle fontSize={['2em', '2.5em', '3em']} mb={[2, 3, 4]}>
            {language === 'pt' ? "Serviços e Soluções" : language === 'es' ? "Servicios y Soluciones" : "Services and Solutions"}
          </ServiceTitle>
        </ServiceSubtitle>
        <ServiceImage src={droneImage} alt="Drone" />

        <ServiceGrid>
          <ServiceBox p={[2, 3]} mb={[3, 3, 4]}>
            <ServiceBoxTitle fontSize={['1.2em', '1.4em', '1.6em']}>
              {language === 'pt' ? "Conexão e Análise de Imagens" : language === 'es' ? "Conexión y Análisis de Imágenes" : "Image Connection and Analysis"}
            </ServiceBoxTitle>
            <ServiceBoxText fontSize={['0.9em', '1em', '1.1em']}>
              {language === 'pt'
                ? "Nossa IA conecta-se a câmeras e drones, analisando em tempo real as imagens recebidas para detectar atividades suspeitas."
                : language === 'es'
                ? "Nuestra IA se conecta a cámaras y drones, analizando en tiempo real las imágenes recibidas para detectar actividades sospechosas."
                : "Our AI connects to cameras and drones, analyzing received images in real-time to detect suspicious activities."}
            </ServiceBoxText>
          </ServiceBox>

          <ServiceBox p={[2, 3]} mb={[3, 3, 4]}>
            <ServiceBoxTitle fontSize={['1.2em', '1.4em', '1.6em']}>
              {language === 'pt' ? "Classificação de Alertas" : language === 'es' ? "Clasificación de Alertas" : "Alert Classification"}
            </ServiceBoxTitle>
            <ServiceBoxText fontSize={['0.9em', '1em', '1.1em']}>
              {language === 'pt'
                ? "As imagens capturadas são automaticamente classificadas para identificar alertas de segurança, garantindo uma resposta rápida."
                : language === 'es'
                ? "Las imágenes capturadas se clasifican automáticamente para identificar alertas de seguridad, asegurando una respuesta rápida."
                : "Captured images are automatically classified to identify security alerts, ensuring a quick response."}
            </ServiceBoxText>
          </ServiceBox>

          <ServiceBox p={[2, 3]} mb={[3, 3, 4]}>
            <ServiceBoxTitle fontSize={['1.2em', '1.4em', '1.6em']}>
              {language === 'pt' ? "Notificação de Alertas" : language === 'es' ? "Notificación de Alertas" : "Alert Notification"}
            </ServiceBoxTitle>
            <ServiceBoxText fontSize={['0.9em', '1em', '1.1em']}>
              {language === 'pt'
                ? "Após a classificação, os alertas são imediatamente enviados para grupos de WhatsApp e e-mails dos responsáveis pela segurança."
                : language === 'es'
                ? "Después de la clasificación, las alertas se envían inmediatamente a grupos de WhatsApp y correos electrónicos de los responsables de seguridad."
                : "After classification, alerts are immediately sent to WhatsApp groups and emails of security personnel."}
            </ServiceBoxText>
          </ServiceBox>
        </ServiceGrid>
      </ServiceCategory>
    </ServicesWrapper>
  );
};

export default Services;
