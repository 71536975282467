import React from 'react';
import styled from 'styled-components';
import inovacaoImg from './images/Inovacao.png'; // Ajuste o caminho conforme necessário

const InnovationSection = styled.div`
  position: relative;
  background-image: url(${inovacaoImg});
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  padding: 30px;
  height: 900px;
  flex-wrap: wrap;  /* Garantir que os elementos se ajustem corretamente */

  @media (max-width: 1024px) {
    padding: 20px;
  }

  @media (max-width: 480px) {
    padding: 15px;
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
`;

const InnovationContent = styled.div`
  position: relative;
  z-index: 2;
  max-width: 1200px;
  padding: 0 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  align-items: center;  /* Alinha os elementos no centro */

  @media (max-width: 1024px) {
    max-width: 100%;
    flex-direction: column;
    align-items: center;
  }

  @media (max-width: 480px) {
    padding: 0 10px;
  }
`;

const Title = styled.h3`
  font-size: 2.8em;
  color: #D8A43E;
  font-weight: bold;
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 40px;

  @media (max-width: 1024px) {
    font-size: 2.5em;
    margin-bottom: 30px;
  }

  @media (max-width: 480px) {
    font-size: 1.8em;
    margin-bottom: 20px;
  }
`;

const InnovationBox = styled.div`
  background: rgba(0, 191, 255, 0.7);
  border-radius: 50px;
  padding: 20px;
  width: 100%;
  max-width: 350px;  /* Garantir que as caixas não sejam muito largas */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
  color: #fff;

  @media (max-width: 768px) {
    width: 90%;
    margin-bottom: 20px;
  }

  @media (max-width: 480px) {
    padding: 10px;
    width: 100%;
  }
`;

const BoxText = styled.p`
  font-size: 1.8em;
  line-height: 1.6;

  @media (max-width: 1024px) {
    font-size: 1.4em;
    line-height: 1.4;
  }

  @media (max-width: 480px) {
    font-size: 1em;
    line-height: 1.2;
  }
`;

const translations = {
  pt: {
    title: "Inovadora em segurança eletrônica",
    box1: "• Monitoramento e Alertas com uso de Inteligência Artificial.",
    box2: "• Alertas instantâneos no celular, computador ou tablet.",
    box3: "• Reconhecimento facial com uso de aprendizado de máquina (Machine Learning)."
  },
  es: {
    title: "Innovadora en seguridad electrónica",
    box1: "• Monitoreo y alertas con uso de Inteligencia Artificial.",
    box2: "• Alertas instantáneas en el móvil, computadora o tablet.",
    box3: "• Reconocimiento facial con uso de aprendizaje automático (Machine Learning)."
  },
  en: {
    title: "Innovative in electronic security and alerts",
    box1: "• Monitoring and Alerts using Artificial Intelligence.",
    box2: "• Instant alerts on your phone, computer, or tablet.",
    box3: "• Facial recognition using machine learning (Machine Learning and Artificial Intelligence Training)."
  }
};

const Innovation = ({ language }) => {
  const currentTranslations = translations[language] || translations['pt'];

  return (
    <InnovationSection id="innovation-section">
      <Overlay />
      <InnovationContent>
        <Title>{currentTranslations.title}</Title>
        <InnovationBox>
          <BoxText>{currentTranslations.box1}</BoxText>
        </InnovationBox>
        <InnovationBox>
          <BoxText>{currentTranslations.box2}</BoxText>
        </InnovationBox>
        <InnovationBox>
          <BoxText>{currentTranslations.box3}</BoxText>
        </InnovationBox>
      </InnovationContent>
    </InnovationSection>
  );
};

export default Innovation;
